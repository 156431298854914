import useBannerAlert from "./hooks"
import * as S from "./styles"

const BannerAlert = ({ theme }) => {
  const { isOpen, handleCloseBanner } = useBannerAlert()

  return (
    <div css={S.Theme(theme, isOpen)}>
      <div css={S.BannerAlertWrapper}>
        <div role="complementary" css={S.BannerAlertText}>
          <p>
            <span>Não</span> solicitamos pagamentos antecipados ou depósitos na
            aquisição de nenhum produto
          </p>
        </div>
        <div css={S.BannerAlertCloseButton} onClick={handleCloseBanner}>
          <div css={S.BannerAlertCloseButtonLine}></div>
          <div css={S.BannerAlertCloseButtonLine}></div>
        </div>
      </div>
    </div>
  )
}

export default BannerAlert
