import { useEffect, useState } from "react"
import {
  setBannerAlertLocal,
  getBannerAlertLocal,
} from "@mkt/utils/safeLocalStorage"
import isClient from "@mkt/utils/isClient"
import getUrlParams from "@mkt/utils/getUrlParams"

const useBannerAlert = () => {
  const [isOpen, setOpen] = useState(false)

  useEffect(() => {
    const utmSource = getUrlParams().get("utm_source")
    const isClosed = getBannerAlertLocal() || !!utmSource
    setOpen(!isClosed)
  }, [])

  function handleCloseBanner(e) {
    e.preventDefault()
    setOpen(false)
    isClient() && setBannerAlertLocal(false)
  }

  return {
    isOpen,
    handleCloseBanner,
  }
}

export default useBannerAlert
