const baseName = "iq_marketplace_local_"

const BANNER_ALERT = `${baseName}banner_alert`
const UTM_PARAMS = `${baseName}utm_params`

export function setBannerAlertLocal(value) {
  return localStorage?.setItem(BANNER_ALERT, String(value))
}

export function getBannerAlertLocal() {
  return Boolean(localStorage?.getItem(BANNER_ALERT))
}

export function setUtmParamsLocal(values) {
  return localStorage?.setItem(UTM_PARAMS, JSON.stringify(values))
}

export function getUtmParamsLocal() {
  return JSON.parse(localStorage?.getItem(UTM_PARAMS))
}
