const fonts = {
  fontSize: {
    default: "16px",
    sm: "13px",
    lg: "18px",
    xl: "22px",
  },
  fontWeight: {
    normal: "400",
    bold: "700",
    extrabold: "900",
  },
  fontFamily: {
    opensans: ['"open san"', "helvetica", "arial", "sans-serif"],
  },
}

const fontsCP = {
	"font-primary": {
		"font-family": "'Source Sans Pro'",
		"weight": {
			"default": "300"
		}
	},
	"font-secondary": {
		"font-weight": "500",
		"font-family": "'Lexend Deca'",
		"weight": {
			"default": "500",
      "bold": "700"
		}
	},

	"font-colors": {
		"title": "emerald-50",
		"subtitle": "neutral-70",
		"common": "neutral-50"
	},

	"font-variants": {
		"heading-one": {
			"font-size": "32px",
			"line-height": "1.4"
		},
		"heading-two": {
			"font-size": "28px",
			"line-height": "1.4"
		},
		"heading-three": {
			"font-size": "24px",
			"line-height": "1.4"
		},
		"heading-four": {
			"font-size": "20px",
			"line-height": "1.4"
		},
		"heading-five": {
			"font-size": "20px",
			"line-height": "28px"
		},
		"heading-six": {
			"font-size": "16px",
			"line-height": "24px"
		},

    "body-extralarge": {
			"font-size": "20px",
			"line-height": "1.5"
		},
		"body-large": {
			"font-size": "18px",
			"line-height": "26px"
		},
		"body-medium": {
			"font-size": "16px",
			"line-height": "1.5"
		},
		"body-small": {
			"font-size": "14px",
			"line-height": "1.5"
		},
		"body-micro": {
			"font-size": "12px",
			"line-height": "20px"
		},

		"number-large": {
			"font-size": "24px",
			"line-height": "32px"
		},
		"number-medium": {
			"font-size": "16px",
			"line-height": "24px"
		},
		"number-small": {
			"font-size": "14px",
			"line-height": "22px"
		},
		"number-micro": {
			"font-size": "12px",
			"line-height": "20px"
		}
	},

	"heading": {
		"one": {
			"desk": {
				"font-size": "40px",
				"line-height": "48px"
			},
			"mobile": {
				"font-size": "40px",
				"line-height": "48px"
			}
		},
		"two": {
			"desk": {
				"font-size": "32px",
				"line-height": "40px"
			},
			"mobile": {
				"font-size": "24px",
				"line-height": "32px"
			}
		},
		"three": {
			"desk": {
				"font-size": "28px",
				"line-height": "36px"
			},
			"mobile": {
				"font-size": "20px",
				"line-height": "28px"
			}
		},
		"four": {
			"desk": {
				"font-size": "24px",
				"line-height": "32px"
			},
			"mobile": {
				"font-size": "16px",
				"line-height": "24px"
			}
		},
		"five": {
			"desk": {
				"font-size": "20px",
				"line-height": "28px"
			},
			"mobile": {
				"font-size": "12px",
				"line-height": "20px"
			}
		},
		"six": {
			"desk": {
				"font-size": "16px",
				"line-height": "24px"
			},
			"mobile": {
				"font-size": "8px",
				"line-height": "16px"
			}
		}
	},

	"button": {
    "font-family": {
      "default": "Source Sans Pro",
      "text": "Lexend Deca"
    },
		"font-weight": {
      "light": "300",
      "default": "600",
      "bold": "700"
    },
		"line-height": {
      "default": "1.5",
      "text": "1"
    },
		"font-size": "16px"
	}
}

const colorsCP = {
	"blueberry-0": "#F5F2FF",
	"blueberry-10": "#E4E2FF",
	"blueberry-20": "#BDB8FF",
	"blueberry-30": "#776DFF",
	"blueberry-40": "#9896FC",
	"blueberry-50": "#5E52F9",
	"blueberry-60": "#453AD3",
	"blueberry-70": "#2319A5",
	"blueberry-80": "#1C1277",

	"cyan-0": "#EBFDFE",
	"cyan-10": "#CDFBFE",
	"cyan-20": "#7FF2F8",
	"cyan-30": "#20E9F3",
	"cyan-40": "#00D3E8",
	"cyan-50": "#00BFD9",
	"cyan-60": "#0086A6",
	"cyan-70": "#006887",
	"cyan-80": "#023949",

	"gray-0": "#FFFFFF",
	"gray-10": "#F9F9F9",
	"gray-20": "#EFEFEF",
	"gray-30": "#D5D5D5",
	"gray-40": "#B5B5B5",
	"gray-50": "#666666",
	"gray-60": "#444444",
	"gray-100": "#1A1A1A",

	"red-0": "#FFF2F2",
	"red-10": "#FFB7B7",
	"red-20": "#FE7F7F",
	"red-30": "#F95151",
	"red-40": "#E33D3D",
	"red-50": "#A92525",
	"red-60": "#871E1E",
	"red-100": "#3E0303",

	"yellow-0": "#FDFAF2",
	"yellow-10": "#FFF5CC",
	"yellow-20": "#FFEEA6",
	"yellow-30": "#FFDE60",
	"yellow-40": "#EDC322",
	"yellow-50": "#DBAF03",
	"yellow-60": "#B99200",
	"yellow-100": "#6A5603",

	"green-0": "#EFFDE6",
	"green-10": "#BBF7B1",
	"green-20": "#84F593",
	"green-30": "#31E981",
	"green-40": "#04DD4C",
	"green-50": "#00AA2D",
	"green-60": "#008722",
	"green-100": "#00421D",

	"pink-0": "#FFF2F6",
	"pink-10": "#FFC6DD",
	"pink-20": "#FF99C6",
	"pink-30": "#FF499E",
	"pink-40": "#ED1275",
	"pink-50": "#D3005F",
	"pink-60": "#AF004F",
	"pink-70": "#87003D",

	"purple-0": "#F9E1FF",
	"purple-10": "#EDBEF9",
	"purple-20": "#E39CF2",
	"purple-30": "#D266E5",
	"purple-40": "#C844DA",
	"purple-50": "#BC2EC6",
	"purple-60": "#A91FA9",
	"purple-70": "#871780",

  "emerald-10": "#CCF2E4",
  "emerald-30": "#94F0CF",
  "emerald-40": "#04D98C",
  "emerald-50": "#026441",
  "emerald-60": "#013220",

  "neutral-00": "#FFFFFF",
  "neutral-10": "#F6F7FA",
  "neutral-20": "#E2E4E9",
  "neutral-30": "#C4C9D4",
  "neutral-50": "#454A54",
  "neutral-70": "#16181D",

  "blue-40": "#0048DB",
  "blue-50": "#1737A4",
  "blue-60": "#18256C",

  "error-10": "#FFE5E5",
  "error-40": "#E52222",
  "error-50": "#991717"
}


export { fonts, fontsCP, colorsCP }
